.listContainer {
  overflow: hidden;
  border-top: 1px solid transparent;
  transition-delay: 0.5s;
}

.listContainer.open {
  transition-delay: 0s;
  border-top-color: var(--grey-3);
}

.container:not(:first-child) > .headerContainer {
  border-top: 1px solid var(--grey-2);
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 18px;
  padding-left: 64px;
}

.itemText {
  flex-grow: 1;
}

.tabName {
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.2;
}

.tabName:hover {
  text-decoration: underline;
}

.regionToggle {
  font-family: "Roboto Mono", monospace;
  font-style: italic;
  color: var(--blue-4);
  font-size: 14px;
  cursor: pointer;
  outline: none;
  margin-top: 6px;
}

.regionToggle:hover {
  text-decoration: underline;
}

.icon {
  color: var(--grey-6);
}

.deleteTab {
  opacity: 0;
  color: var(--grey-3);
  transition: all 0.3s ease-in-out;
}

.deleteTab:hover {
  color: var(--grey-6);
}

.headerContainer:hover .deleteTab {
  opacity: 0.8;
}

.addTab {
  font-weight: 600;
  font-style: italic;
  color: var(--blue-6);
}

.addTab:hover {
  text-decoration: underline;
}

.folder {
  margin-right: 16px;
}
