:root {
  /* Neutrals */
  --grey-0: #fafafa;
  --grey-1: #f2f2f2;
  --grey-2: #ebebeb;
  --grey-3: #b5b5b5;
  --grey-4: #a6a6a6;
  --grey-5: #8f8f8f;
  --grey-6: #626262;
  --grey-7: #4f4f4f;
  --grey-8: #3b3b3b;
  --grey-9: #262626;

  /* Darker Neutrals */
  /*--grey-0: #f7f7f7;*/
  /*--grey-1: #e1e1e1;*/
  /*--grey-2: #cfcfcf;*/
  /*--grey-3: #b1b1b1;*/
  /*--grey-4: #9e9e9e;*/
  /*--grey-5: #7e7e7e;*/
  /*--grey-6: #626262;*/
  /*--grey-7: #515151;*/
  /*--grey-8: #3b3b3b;*/
  /*--grey-9: #222222;*/

  /* Primary */
  --green-0: #e3f9e5;
  --green-1: #c1eac5;
  --green-2: #a3d9a5;
  --green-3: #7bc47f;
  --green-4: #57ae5b;
  --green-5: #3f9142;
  --green-6: #2f8132;
  --green-7: #207227;
  --green-8: #0e5814;
  --green-9: #05400a;

  /* Blue */
  --blue-0: #dceefb;
  --blue-1: #b6e0fe;
  --blue-2: #84c5f4;
  --blue-3: #62b0e8;
  --blue-4: #4098d7;
  --blue-5: #2680c2;
  --blue-6: #186faf;
  --blue-7: #0f609b;
  --blue-8: #0a558c;
  --blue-9: #003e6b;

  /* Red */
  --red-0: #ffeeee;
  --red-1: #facdcd;
  --red-2: #f29b9b;
  --red-3: #e66a6a;
  --red-4: #d64545;
  --red-5: #ba2525;
  --red-6: #a61b1b;
  --red-7: #911111;
  --red-8: #780a0a;
  --red-9: #610404;

  /* Yellow */
  --yellow-0: #fffaeb;
  --yellow-1: #fcefc7;
  --yellow-2: #f8e3a3;
  --yellow-3: #f9da8b;
  --yellow-4: #f7d070;
  --yellow-5: #e9b949;
  --yellow-6: #c99a2e;
  --yellow-7: #a27c1a;
  --yellow-8: #7c5e10;
  --yellow-9: #513c06;

  /*  Yellow - Vivid */
  --yellow-vivid-0: #fffbea;
  --yellow-vivid-1: #fff3c4;
  --yellow-vivid-2: #fce588;
  --yellow-vivid-3: #fadb5f;
  --yellow-vivid-4: #f7c948;
  --yellow-vivid-5: #f0b429;
  --yellow-vivid-6: #de911d;
  --yellow-vivid-7: #cb6e17;
  --yellow-vivid-8: #b44d12;
  --yellow-vivid-9: #8d2b0b;

  /* Light Blue - Vivid */
  --light-blue-vivid-0: #e3f8ff;
  --light-blue-vivid-1: #b3ecff;
  --light-blue-vivid-2: #81defd;
  --light-blue-vivid-3: #5ed0fa;
  --light-blue-vivid-4: #40c3f7;
  --light-blue-vivid-5: #2bb0ed;
  --light-blue-vivid-6: #1992d4;
  --light-blue-vivid-7: #127fbf;
  --light-blue-vivid-8: #0b69a3;
  --light-blue-vivid-9: #035388;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

svg {
  box-sizing: content-box;
}

body {
  direction: ltr;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--grey-8);
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

a:visited,
a:link {
  text-decoration: none;
}

input:focus {
  outline: none;
}

button {
  color: inherit;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
}

th {
  font-weight: initial;
}

hr {
  width: 100%;

  margin: 0;
  padding: 0;
  border: 0;

  border-bottom: 1px solid var(--grey-4);
}

ul {
  margin-left: 20px;
}
