.wrapper {
  border-radius: 6px;
  border: 1px solid var(--grey-4);
}

.wrapper p {
  line-height: 1.2;
  padding: 0.65em 0;
}

.projectMappingWrapper {
  display: flex;
}

.projectMappingWrapper:not(:last-child) {
  border-bottom: 1px solid var(--grey-3);
}

.projectWrapper {
  flex: 1;
  /*background-color: lightblue;*/
  padding: 16px;
  border-right: 1px solid var(--grey-3);
}

.taskMappingWrapper {
  flex: 2.25;

  display: flex;
  flex-direction: column;
}

.taskMapping {
  flex: 1;

  display: flex;
}

.taskMapping:not(:last-child) {
  border-bottom: 1px solid var(--grey-2);
}

.taskWrapper {
  flex: 1;
  /*background-color: lightcoral;*/
  padding: 16px;

  border-right: 1px solid var(--grey-2);
}

.surveyWrapper {
  flex: 1.25 1 auto;
  /*background-color: lightgoldenrodyellow;*/
  padding: 16px;
}

.add {
  font-weight: 600;
  font-style: italic;
  color: var(--blue-6);
}

.add:hover {
  text-decoration: underline;
}
